<template>
  <aside>
    <header>
      <h2>Select a Banner</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main>
      <p>Selecting multiple banners will enable your award to pick a design at random each time it's earned.</p>
      <ul class="banners">
        <li v-for="(banner, index) in banners" :class="{'active': selected.includes(banner.award_card_id)}" :key="`banner_${index}`">
          <a href="#" @click.prevent="selectBanner(banner)">
            <img :src="`https://img-cdn.hithrive.com/teams-award-skins/${banner.award_card_id}.png`" height="100" />
          </a>
        </li>
      </ul>
    </main>

    <footer>
      <button class="btn btn-sm btn-green" :disabled="selected.length === 0" @click="save">Select banner{{selected.length > 1 ? 's' : ''}}</button>
    </footer>
  </aside>
</template>

<script>
export default {
  props: ['data'],
  computed: {
    banners() {
      const filter_params = {
        type: 'AWARD_SKIN'
      };

      return _.filter( this.icons, filter_params );
    }
  },
  watch: {
    
  },
  data() {
    return {
      selected: [],
      icons: null
    }
  },
  created() {
    this.populateIcons()
    this.selected = Array.isArray(this.data) ? this.data : [];
  },
  methods: {
    close() {
      this.$emit('done')
    },
    async populateIcons() {
      const resp = await this.$api.Team.get_icons()
      this.icons = resp;
    },
    selectBanner(banner) {
      const index = this.selected.findIndex(s => s === banner.award_card_id);
      if(index > -1) return this.selected.splice(index, 1);
      this.selected.push(banner.award_card_id);
    },
    save() {
      this.$emit('done', 'BANNER_SELECTED', this.selected)
    }
  }
}
</script>

<style lang="scss" scoped>
.banners {
  padding: 0;
  margin: 0;
  display: block;

  > li {
    display: flex;

    +li {
      margin-top: 15px;
    }

    > a {
      display: block;
      border-radius: 6px;
      overflow: hidden;
      height: 100px;

      &:hover {
        box-shadow: $primary 0 0 0 1px, rgba($primary,0.5) 0 0 3px;
      }

      > img {
        display: block;
      }
    }

    &.active {
      > a {
        box-shadow: $primary 0 0 0 1px, rgba($primary,0.3) 0 0 0 4px;
      }
    }
  }
}
</style>